// Generated by Framer (575ceb6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/0jtlswBvrookcx7EpjBD/Video.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["kkVDh9uZ9", "slYWRV0cy"];

const variantClassNames = {kkVDh9uZ9: "framer-v-p35gm", slYWRV0cy: "framer-v-agau2v"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {paused: "kkVDh9uZ9", playing: "slYWRV0cy"};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "kkVDh9uZ9", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "kkVDh9uZ9", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Qv2lc", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-p35gm", className)} data-framer-name={"paused"} layoutDependency={layoutDependency} layoutId={"kkVDh9uZ9"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({slYWRV0cy: {"data-framer-name": "playing"}}, baseVariant, gestureVariant)}><motion.div className={"framer-qk8ir1-container"} layoutDependency={layoutDependency} layoutId={"efiXo6q2k-container"} transformTemplate={transformTemplate} transition={transition}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={12} bottomLeftRadius={12} bottomRightRadius={12} canvasPlay controls={false} height={"100%"} id={"efiXo6q2k"} isMixedBorderRadius={false} layoutId={"efiXo6q2k"} loop muted objectFit={"fill"} playing={false} posterEnabled={false} srcFile={new URL("assets/6nwtw2rhDthzhEW899gWRFFUkdE.mp4", import.meta.url).href} srcType={"Upload"} srcUrl={"https://assets.mixkit.co/videos/preview/mixkit-ice-cream-glass-of-red-soda-5094-small.mp4"} startTime={0} style={{width: "100%"}} topLeftRadius={12} topRightRadius={12} volume={25} width={"100%"} {...addPropertyOverrides({slYWRV0cy: {playing: true}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Qv2lc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Qv2lc .framer-fjclrk { display: block; }", ".framer-Qv2lc .framer-p35gm { height: 432px; overflow: hidden; position: relative; width: 624px; }", ".framer-Qv2lc .framer-qk8ir1-container { flex: none; height: auto; left: 50%; position: absolute; top: 50%; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 432
 * @framerIntrinsicWidth 624
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"slYWRV0cy":{"layout":["fixed","fixed"]}}}
 */
const FramergK_jTPQR1: React.ComponentType<Props> = withCSS(Component, css, "framer-Qv2lc") as typeof Component;
export default FramergK_jTPQR1;

FramergK_jTPQR1.displayName = "feature-video-1";

FramergK_jTPQR1.defaultProps = {height: 432, width: 624};

addPropertyControls(FramergK_jTPQR1, {variant: {options: ["kkVDh9uZ9", "slYWRV0cy"], optionTitles: ["paused", "playing"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramergK_jTPQR1, [...VideoFonts])